.cities {
  display: flex;
  flex-grow: 1;
  background-color: white;
  overflow-y: hidden;
}

.cities__places-container {
  display: flex;
  padding-right: 0;

  &--empty {
    padding-right: 0;
  }
}

.cities__no-places {
  position: relative;
  width: 498px;
  padding-left: 18px;

  &::after {
    content: "";
    position: absolute;
    width: 12.06vh;
    min-width: 100px;
    max-width: 120px;
    height: 100%;
    bottom: 0;
    right: -48px;
    background-color: white;
    border-right: 6px solid $main;
    transform: skew(-6.5deg);
  }
}

.cities__places {
  width: 572px;
  margin-top: 2px;
  margin-right: 2px;
  padding-top: 29px;
  padding-bottom: 7px;
  padding-left: 9px;
  overflow-y: auto;
}

.cities__places-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  padding-left: 5px;
}

.cities__place-card {
  width: 260px;
  margin-left: 8px;
  margin-bottom: 24px;
}

.page__main--index-empty {
  .cities__right-section {
    background-image: url("../img/no-places@2x.png");
    background-size: auto 119%;
    background-repeat: no-repeat;
    background-position: right 100%;

    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      background-image: url("../img/no-places@2x.png");
    }

    @media (max-height: 780px) {
      background-position: right center;
      background-size: 100% auto;
    }
  }
}

.cities__right-section {
  display: flex;
  flex-grow: 1;
}

.cities__map {
  width: 100%;
  align-self: stretch;
  background-image: url("../img/map.jpg");
  background-size: 682px auto;
  background-repeat: no-repeat;
  background-position: -170px center;

  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    background-image: url("../img/map@2x.jpg");
    background-size: 682px 794px;
  }

  @media (min-height: 980px) {
    background-size: cover;
  }
}

.cities__status-wrapper {
  position: relative;
  z-index: 1;
  width: auto;
  margin-top: 25.4vh;
  margin-right: auto;
  margin-left: auto;
  padding-top: 62px;
  text-align: center;
  background-image: url("../img/ico-no-results.svg");
  background-size: 45px 47px;
  background-position: center top;
  background-repeat: no-repeat;
}

.cities__status {
  display: block;
  margin-bottom: 5px;
  font-size: 32px;
  line-height: 1.1875;
}

.cities__status-description {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 45px;
  font-size: 16px;
  line-height: 1.5;
}
