.page {
  background-color: white;

  &--login {
    display: flex;
    flex-direction: column;
    width: 1144px;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(to right, $bg 509px, transparent 509px),
                      url("../img/amsterdam.jpg");
    background-position: top left, right top;
    height: 100vh;
    background-size: auto, auto 100%;
    background-repeat: no-repeat, no-repeat;
    overflow: hidden;

    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      background-image: linear-gradient(to right, $bg 509px, transparent 509px),
                        url("../img/amsterdam@2x.jpg");
    }

    @media (max-height: 720px) {
      background-size: auto, auto 715px;
    }
  }

  &--favorites-empty {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  &--main {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  &--gray {
    background-color: $bg;
  }
}

.page__main {
  &--property {
    padding-bottom: 129px;
  }

  &--login {
    display: flex;
    flex-grow: 1;
  }

  &--favorites {
    padding-top: 11px;
  }

  &--favorites-empty {
    display: flex;
    flex-grow: 1;

    .page__favorites-container {
      display: flex;
    }
  }

  &--index {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: hidden;
  }
}

.page__login-container {
  display: flex;
}
