.property__gallery-container {
  padding-left: 52px;
  padding-right: 52px;
}

.property__gallery {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  width: 785px;
  margin-right: auto;
  margin-left: auto;
  max-height: 452px;
  margin-bottom: 30px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 34px;
    height: 100%;
    background-image: url("../img/triangle.svg");
    background-repeat: no-repeat;
    background-size: 34px 452px;
    transform: rotate(180deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    right: 0;
    width: 34px;
    height: 100%;
    background-image: url("../img/triangle.svg");
    background-repeat: no-repeat;
    background-size: 34px 452px;
  }
}

.property__image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  margin-right: 2px;
  width: 260px;
  height: 200px;
  overflow: hidden;

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.property__image {
  display: block;
  min-width: 100%;
  min-height: 100%;
  flex-grow: 1;
}

.property__container {
  position: relative;
  overflow-x: hidden;
}

.property__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 613px;
  margin-right: auto;
  margin-left: auto;
}

.property__mark {
  margin-bottom: 8px;
  padding: 7px 11px 3px 8px;
  font-size: 16px;
  line-height: 1.1875;
  font-weight: 700;
  font-style: oblique;
  color: white;
  background-color: $main;
  transform: skew(-10deg);
  border-radius: 2px;
  background-color: $main;

  span {
    display: block;
    transform: skew(10deg);
  }
}

.property__name {
  margin-top: 0;
  margin-bottom: 7px;
  padding: 0 28px;
  font-size: 38px;
  line-height: 1.21053;
  font-weight: 700;
  font-style: oblique;
  text-align: center;
}

.property__bookmark-button {
  position: absolute;
  top: 41px;
  right: 93px;
  width: 31px;
  height: 33px;
  margin-top: 2px;

  &:hover .property__bookmark-icon,
  &:focus .property__bookmark-icon {
    stroke: $main;
  }

  &--active .place-card__bookmark-icon {
    stroke: $main;
    fill: $main;
  }
}

.property__bookmark-icon {
  fill: none;
  stroke: #b8b8b8;
  stroke-width: 2;
  transition: fill 0.3s, stroke 0.3s
}

.property__rating {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}

.property__stars {
  width: 147px;
  height: 24px;

  &::before {
    width: 147px;
    background-size: 147px 24px;
  }

  span {
    width: 0%;

    &::before {
      width: 147px;
      background-size: 147px 24px;
    }
  }
}

.property__rating-value {
  margin-left: 5px;
  padding-top: 2px;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  font-style: oblique;
}

.property__features {
  @include list-reset;
  display: flex;
  margin-bottom: 38px;
  margin-left: -64px;
}

.property__feature {
  margin-left: 64px;
  padding-left: 18px;
  font-size: 16px;
  line-height: 1.3;
  background-repeat: no-repeat;

  &--entire {
    background-image: url("../img/ico-place.svg");
    background-size: 13px 16px;
  }

  &--bedrooms {
    background-image: url("../img/ico-bedrooms.svg");
    background-size: 14px 18px;
  }

  &--adults {
    background-image: url("../img/ico-adults.svg");
    background-size: 13px 12px;
    background-position: left 3px;
  }
}

.property__price {
  position: relative;
  margin-bottom: 56px;

  &::before {
    content: "";
    position: absolute;
    top: 18px;
    left: calc(100% + 12px);
    width: 345px;
    height: 1px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.01), #7ca7d5);
  }

  &::after {
    content: "";
    position: absolute;
    top: 18px;
    right: calc(100% + 11px);
    width: 425px;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.01), #6899ce);
  }
}

.property__price-value {
  position: relative;
  padding-right: 8px;
  padding-left: 6px;
  font-size: 32px;
  line-height: 1.1875;
  font-weight: 700;
  font-style: oblique;

  &::after {
    content: "";
    position: absolute;
    top: -7px;
    right: -2px;
    height: 52px;
    width: 2px;
    background-color: $main;
    transform: skew(-12deg);
  }
}

.property__price-text {
  font-size: 18px;
  line-height: 1.223;
  font-weight: 700;
  font-style: oblique;
  opacity: 0.48;
}

.property__inside {
  width: 100%;
  margin-bottom: 52px;
}

.property__inside-title {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.1667;
  font-weight: 700;
  font-style: oblique;
  text-align: center;
  color: black;
}

.property__inside-list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.property__inside-item {
  position: relative;
  width: 100%;
  max-width: 174px;
  padding-left: 18px;
  font-size: 16px;
  line-height: 1.75;
  color: black;

  &::before {
    content: "";
    position: absolute;
    top: 14px;
    left: 0;
    width: 12px;
    height: 1px;
    background-color: black;
  }
}

.property__host-title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 1.1667;
  font-weight: 700;
  font-style: oblique;
  text-align: center;
  color: black;
}

.property__host-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  padding-right: 16px;
}

.property__avatar-wrapper {
  position: relative;
  width: 74px;
  min-width: 74px;
  height: 74px;
  margin-bottom: 7px;

  &--pro::after {
    content: "";
    position: absolute;
    top: -3px;
    right: -16px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: $orange;
    background-image: url("../img/star-white.svg");
    background-size: 20px 19px;
    background-position: center 6px;
    background-repeat: no-repeat;
  }
}

.property__user-name {
  font-size: 16px;
  line-height: 1.187;
  font-weight: 700;
  color: black;
}

.property__user-status {
  font-size: 12px;
  line-height: 1.167;
  color: #696969;
}

.property__description {
  margin-bottom: 52px;
}

.property__text {
  margin-top: 0;
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 1.75;
  color: black;
}

.property__reviews {
  width: 100%;
  margin-bottom: 55px;
}

.property__map {
  width: 100%;
  height: 579px;
  margin-bottom: 50px;
  background-image: url("../img/map-big.jpg");
  background-repeat: no-repeat;
  background-size: 1144px auto;
  background-position: center top;

  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    background-image: url("../img/map-big@2x.jpg");
    background-size: 1144px auto;
  }
}
