html,
body {
  width: 100%;
  min-width: 1144px;
  margin: 0;
  padding: 0;
  font-family: $rubik;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.15;
  color: $text;
  background-color: $bg;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// main {
//   flex-grow: 1;
// }

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s, opacity 0.3s;
  cursor: pointer;
  outline: none;
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  width: 1144px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 58px;
  padding-right: 58px;
}
