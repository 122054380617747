.near-places {
  margin: 0 16px 0 12px;
  padding-bottom: 27px;
  border-bottom: 2px solid rgba(#dedede, 0.5);
}

.near-places__title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 1.1667;
  font-weight: 700;
  font-style: oblique;
  text-align: center;
  color: black;
}

.near-places__list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -8px;
  padding-left: 3px;
}

.near-places__card {
  width: 260px;
  margin-left: 8px;
  margin-bottom: 24px;
}
