.rating__stars {
  position: relative;
  display: block;
  font-size: 0;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    background: url("../img/stars.svg") transparent no-repeat center;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 100%;
    overflow: hidden;

    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      background: url("../img/stars-active.svg") transparent no-repeat center;
    }
  }
}
