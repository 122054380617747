.login {
  position: relative;
  width: 520px;
  padding-top: 19.6vh;
  padding-right: 60px;
  padding-left: 13px;

  &::after {
    content: "";
    position: absolute;
    width: 12.03vh; // 86px;
    min-width: 195px;
    height: 100vh;
    min-height: 450px;
    bottom: 0;
    right: -2px;
    background-color: $bg;
    border-right: 6px solid $main;
    transform: skew(-6.5deg);
  }
}

.login__title {
  position: relative;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 28px;
  font-size: 32px;
  line-height: 1.1875;
  font-weight: 700;
  font-style: oblique;
}

.login__form {
  position: relative;
  z-index: 1;
  width: 341px;
}

.login__input-wrapper {
  margin-right: 2px;
}

.login__input {
  width: 100%;
  margin-bottom: 24px;
}

.login__submit {
  width: 100%;
}
