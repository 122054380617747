.reviews__title {
  margin-top: 0;
  margin-bottom: 37px;
  font-size: 24px;
  line-height: 1.1667;
  font-weight: 700;
  font-style: oblique;
  color: black;
  text-align: center;
}

.reviews__avatar-wrapper {
  min-width: 54px;
  width: 54px;
  height: 54px;
  margin-bottom: 10px;
}

.reviews__avatar {
  display: block;
}

.reviews__user {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 54px;
  margin-right: 22px;
}

.reviews__user-name {
  font-size: 14px;
  line-height: 1.2143;
  color: black;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.reviews__list {
  @include list-reset;
}

.reviews__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
}

.reviews__text {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.75;
  color: black;
}

.reviews__rating {
  margin-bottom: 7px;
}

.reviews__stars {
  width: 98px;
  height: 16px;

  &::before {
    width: 98px;
    background-size: 98px 16px;
  }

  span {
    width: 0%;

    &::before {
      width: 98px;
      background-size: 98px 16px;
    }
  }
}

.reviews__time {
  font-size: 14px;
  line-height: 1;
  color: #5d5d5d;
}

.reviews__form {
  margin-right: -30px;
  padding-left: 76px;
}

.reviews__label {
  display: inline-block;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 1.2143;
  font-weight: 700;
  font-style: oblique;
  color: black;
}

.reviews__textarea {
  width: 568px;
  height: 92px;
  margin-bottom: 12px;
  padding: 15px 16px;
}

.reviews__rating-form {
  margin-bottom: 21px;
}

.reviews__button-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.reviews__help {
  width: 402px;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.334;
}

.reviews__star {
  padding-left: 15px;
  background-image: url("../img/star-active.svg");
  background-size: 12px 11px;
  background-repeat: no-repeat;
}

.reviews__text-amount {
  font-weight: 700;
}

.reviews__submit {
  width: 143px;
  font-size: 16px;
  line-height: 1.1875;
}
