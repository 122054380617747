.locations {
  &--login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0 51px 63px 130px;

    .locations__item-link {
      padding: 9px 21px 6px 16px;
    }
  }

  &--current .locations__item {
    margin: 0;
  }

  &--current .locations__item-link {
    color: white;
    background-color: $main;
    text-shadow: 1px 0 0,
                 0.5px 0 0,
                 -1px 0 0;
  }
}

.locations__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 16px 3px 33px;
}

.locations__item {
  display: block;
  margin-right: 36px;
  margin-bottom: 19px;
}

.favorites__locations {
  .locations__item-link {
    min-width: 137px;
    padding: 9px 14px 6px 15px;
    text-align: center;
    letter-spacing: 0.9px; //
  }
}

.locations__item-link {
  display: block;
  padding: 9px 21px 6px 11px;
  font-size: 19px;
  line-height: 1.211;
  font-weight: 300;
  font-style: oblique;
  transform: skew(-15deg);
  border-radius: 3px;
  transition: background 0.3s, color 0.3s, text-shadow 0.3s;

  span {
    display: block;
    transform: skew(15deg);
  }

  &:hover,
  &:focus {
    text-shadow: 1px 0 0,
                 0.5px 0 0,
                 -1px 0 0;
  }

  &.tabs__item--active {
    text-shadow: 1px 0 0,
                 0.5px 0 0,
                 -1px 0 0;
    color: white;
    background-color: $main;
  }
}
